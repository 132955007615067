@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@layer base {
  :root{
    --light: #EEEEEE;
    --hv1: #ffff00;
    --hv2: #c8c814;
    --hv3: #ee9611;
    --sundOr: #f49600;
    --sundOr2: #d18100;
    --sundGr: #c2d1de;
    --sundGrr: #515e76;
    --sundPr: #1e1a34;
  }
  html {
    font-family: Poppins, system-ui, sans-serif;
    background: var(--light);
    scroll-behavior: smooth;
  }
  h1{
    @apply text-3xl md:text-5xl font-bold uppercase text-[color:var(--sundOr)];
  }
  h2{
    @apply text-xl md:text-xl font-semibold uppercase text-[color:var(--sundOr)];
  }
  p{
    @apply text-base leading-8;
  }
  b{
    @apply underline decoration-[color:var(--sundOr)] underline-offset-8 font-medium;
  }
  #nav-links .active{
    @apply underline underline-offset-8;
  }  
  .btn-primary {
    @apply mt-6 py-2 px-4 rounded bg-[color:var(--sundOr)] text-black hover:bg-[color:var(--sundOr2)]  shadow hover:shadow-lg font-semibold hover:border-transparent border border-transparent uppercase;
  }
  .btn-secondary {
    @apply py-2 px-4 rounded bg-transparent border border-white text-white uppercase hover:bg-[color:var(--sundOr2)]  font-semibold hover:border-transparent;
  }
  form label {
    @apply py-4 px-0 text-black;
  }
  form input, form textarea, form select {
    @apply p-4 rounded text-black;
  }
  img{
    @apply shadow;
  }
}
@media only screen and (max-width: 768px) {
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value (0.5) for the desired opacity */
    z-index: 49; /* Set a z-index lower than your navbar (z-50) */
    display: block; /* Initially hidden */
  }
}
select:focus-visible{
  outline: none;
}
.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.embla {
  --slide-spacing: 1rem;
  --slide-size: 100%;
  --slide-height: 19rem;
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  position: relative;
  max-height: 600px;
}
.embla__slide__img {
  display: block;
  height: var(--slide-height);
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.embla-thumbs {
  --thumbs-slide-spacing: 0.8rem;
  --thumbs-slide-height: 9rem;
  margin-top: var(--thumbs-slide-spacing);
}
.embla-thumbs__viewport {
  overflow: hidden;
}
.embla-thumbs__container {
  display: flex;
  flex-direction: row;
  margin-left: calc(var(--thumbs-slide-spacing) * -1);
}
.embla-thumbs__slide {
  flex: 0 0 28%;
  min-width: 0;
  padding-left: var(--thumbs-slide-spacing);
  position: relative;
}
@media (min-width: 576px) {
  .embla-thumbs__slide {
    flex: 0 0 18%;
  }
}
.embla-thumbs__slide__button {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: block;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  opacity: 0.2;
  transition: opacity 0.2s;
}
.embla-thumbs__slide--selected .embla-thumbs__slide__button {
  opacity: 1;
}
.embla-thumbs__slide__img {
  display: block;
  height: var(--thumbs-slide-height);
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.embla__progress {
  z-index: 1;
  background-color: var(--sundPr);
  position: relative;
  height: 3px;
  border-radius: 0.2rem;
  left: 0;
  right: 0;
  bottom: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  pointer-events: none;
  width: 21rem;
  max-width: 90%;
  overflow: hidden;
}
.embla__progress__bar {
  background: var(--sundOr);
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: -100%;
}
/* Add these styles to your CSS file */
.chevron-icon {
  transition: transform 0.3s ease; /* Transition for rotation */
}

.rotate {
  transform: rotate(180deg);
}

.language-options {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out; /* Transition for opening and closing */
}

.language-options.open {
  max-height: 200px; /* Adjust the max height as needed */
}
/* Add your custom styles in your CSS file or stylesheet */

/* Style the breadcrumb links */
.breadcrumb-link {
  text-decoration: none;
  color: black; /* Customize the link color */
}

/* Style the current page (last breadcrumb) */
.current-page {
  color: black; /* Customize the current page color */
  font-weight: bolder;
}

@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid black;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: rotate 2s linear infinite;
}
